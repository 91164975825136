<template>
  <div class="my-5" title="Charcoal types in nigeria">
    <v-row>
      <v-col cols="12" sm="6" lg="3"
        v-for="image in images"
        :key="image.id"
        :title="image.alt"
      >
        <v-card
          height="100%"
          elevation="4"
        >
          <v-img
            height="400px"
            contain
            :src="image.src"
            :alt="image.alt"
            :title="image.alt"
          ></v-img>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import { uuid } from '@/services'

export default Vue.extend ({
  name: "CharcoalProducts",
  data() {
    return {
      images: [
        {
          id: uuid(),
          src: "uploads/chc1.jpg",
          alt: "Natural Hardwood Charcoal - Nigeria Best Charcoal, Nigerian Best Charcoal Manufacturers"
        },
        {
          id: uuid(),
          src: "uploads/chc2.jpg",
          alt: "Hardwood Charcoal - Nigerian Hard Wood Charcoal at Best Price"
        },
        {
          id: uuid(),
          src: "uploads/chc3.jpg",
          alt: "Ayin Hardwood Charcoal - Nigeria Charcoal Seller, Exporter, Supplier, Wholesale"
        },
        {
          id: uuid(),
          src: "uploads/chc4.jpg",
          alt: "Ayin Charcoal Nigeria - Charcoal Export, Exporter in Nigeria"
        }
      ],
    }
  }
})
</script>
