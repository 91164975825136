<template>
  <div>
      <h2 class="text-center theme-text text-uppercase" title="Uocharcoal, the team behind best charcoal in nigeria">
      Our Team
    </h2>
      <v-carousel
        cycle
        interval="4000"
        height="400"
        show-arrows-on-hover
        hide-delimiters
      >
        <v-carousel-item reverse-transition="fade-transition" v-for="n in 4"
            :key="'c'+ n">
          <v-row>
          <v-col cols="12" sm="6" md="4" lg="3"  v-for="(item, i) in curItems[n]"
            :key="'ct'+i" :title="item.alt"
            >
          <v-img :src="item.src" :alt="item.alt" :title="item.alt" contain max-width="80%" max-height="80%" class="mx-auto"></v-img>
              <h3 class="text-center my-0 py-0">
              {{ item.name }}
            </h3>
            <h4 class="text-center theme-text my-0 py-0">
              {{ item.position }}
            </h4>
          </v-col>
        </v-row>
        </v-carousel-item>
  </v-carousel>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "TeamCarousel",
  data() {
    return {
      items: [
        {
          src: "uploads/team1.jpg",
          name: "Oruese Emagbetere",
          position: "CEO/Visionary",
          alt: "Oruese Emagbetere, CEO/Visionary - Uocharcoal, best of nigeria charocal"
        },
        {
          src: "uploads/team2.jpg",
          name: "Stanley Nnoromele",
          position: "International Operations",
          alt: "Stanley Nnoromele, International Operations - Uocharcoal, best of nigeria charocal"
        },
        {
          src: "uploads/team3.jpg",
          name: "Atase Jennifer Adjarho",
          position: "Logistics",
          alt: "Atase Jennifer Adjarho, Logistics - Uocharcoal, best of nigeria charocal",
        },
        {
          src: "uploads/team4.jpg",
          name: "Godwin Oghenenyerhovwo",
          position: "Quality Control Expert",
          alt: "Godwin Oghenenyerhovwo, Quality Control Expert - Uocharcoal, best of nigeria charocal",
        },
        {
          src: "uploads/team1.jpg",
          name: "Oruese Emagbetere",
          position: "CEO/Visionary",
          alt: "Oruese Emagbetere, CEO/Visionary - Uocharcoal, best of nigeria charocal"
        },
        {
          src: "uploads/team2.jpg",
          name: "Stanley Nnoromele",
          position: "International Operations",
          alt: "Stanley Nnoromele, International Operations - Uocharcoal, best of nigeria charocal"
        },
        {
          src: "uploads/team3.jpg",
          name: "Atase Jennifer Adjarho",
          position: "Logistics",
          alt: "Atase Jennifer Adjarho, Logistics - Uocharcoal, best of nigeria charocal",
        },
      ]
    };
  },
  computed: {

    curItems(){
     let item = {
       1:this.items.slice(0,1),
       2:this.items.slice(1,2),
       3:this.items.slice(2,3),
       4:this.items.slice(3,4)
     }
      switch (this.$vuetify.breakpoint.name) {
      case 'sm':
        item = {
          1:this.items.slice(0,2),
          2:this.items.slice(1,3),
          3:this.items.slice(2,4),
          4:this.items.slice(3,5)
        }
        
        break;
      case 'md':
        item = {
          1:this.items.slice(0,3),
          2:this.items.slice(1,4),
          3:this.items.slice(2,5),
          4:this.items.slice(3,6)
        }
        
        break;
      case 'lg':
      case 'xl':
        item = {
          1:this.items.slice(0,4),
          2:this.items.slice(1,5),
          3:this.items.slice(2,6),
          4:this.items.slice(3,7)
        }
        
        break;
    
      default:
        break;
    }

    return item
    }
  }
});
</script>
