<template>
  <div title="Best of Nigeria Charcoal">
    <v-container title="Types of charcoal products in nigeria">
      <v-carousel
        cycle
        interval="4000"
        height="500"
        show-arrows-on-hover
        hide-delimiter-background
      >
        <v-card>
          <v-carousel-item
            v-for="(item,i) in items"
            :title="item.alt"
            :key="i"
            :src="item.src"
            :alt="item.alt"
          ></v-carousel-item>
        </v-card>
      </v-carousel>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "CarouselSlide",
  data() {
    return {
      items: [
        {
          src: "uploads/chcc1.jpg",
          alt: "Natural Hardwood Charcoal - Charcoal Exporter in Nigeria"
        },
        {
          src: "uploads/chcc2.jpg",
          alt: 'Ayin Charcoal - Charcoal exporters in Nigeria, world best exporters'
        },
        {
          src: "uploads/chcc4.jpg",
          alt: 'Non Sparkling Charcoal - CHARCOAL EXPORT IN NIGERIA - TOP SECRETS'
        },
        {
          src: "uploads/chcc5.jpg",
          alt: 'Hardwood Charcoal - Charcoal Suppliers In Nigeria: Exporters, Buyers | BBQ | Lump'
        },
        {
          src: "uploads/chcc6.jpg",
          alt: 'Rosewood Charcoal - Charcoal exporter in Nigeria | Charcoal Supplier | Wholesale'
        }
      ]
    };
  },
});
</script>
