<template>
  <div>
    <v-app>
    <v-app-bar
      app
      dark
      outlined
      clipped-right
      elevation="4"
      max-height="90px"
      :shrink-on-scroll = "!mobile"
      elevate-on-scroll
    >
      <router-link :to="{name: 'home'}">
        <v-img
          position="left"
          height="3rem"
          :max-width="logowidth"
          contain
          src="uploads/uo-logo2.jpg">
        </v-img>
      </router-link>
      <v-spacer></v-spacer>
      
      <div v-show="!mobile">
        <v-btn :to="{name: 'home'}" :color="themeColor" text><v-icon>mdi-home</v-icon> Home</v-btn>
        <v-btn :to="{name: 'quote'}" :color="themeColor" text><v-icon>mdi-clipboard-text</v-icon> Request Quote</v-btn>
        <v-btn :to="{name: 'questions'}" :color="themeColor" text><v-icon>mdi-help-circle</v-icon> FAQ</v-btn>
        <v-btn :to="{name: 'products'}" :color="themeColor" text><v-icon>mdi-view-list</v-icon> Products</v-btn>
        <v-btn :to="{name: 'team'}" :color="themeColor" text><v-icon>mdi-account-multiple</v-icon> Team</v-btn>
        <v-btn :to="{name: 'contact'}" :color="themeColor" text><v-icon>mdi-map-marker-radius</v-icon> Contact</v-btn>
      </div>
      
      
      <v-app-bar-nav-icon @click="drawer=!drawer" v-show="mobile"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>

    <v-navigation-drawer 
      :app="mobile"
      right
      clipped
      stateless
      v-model="drawer"
      v-show="mobile"
    >
      <nav-drawer :hideDrawer="hideDrawer"></nav-drawer>
    </v-navigation-drawer>

    <v-footer dark outlined>
      <div class="mx-auto" title="Uocharcoal, Uriri Oghene Nigeria Ltd, Best charcoal in nigeria">
        <div class="theme-text mt-5">
        <b>Copyright &copy; {{ new Date().getFullYear() }} Uriri Oghene Nigeria Ltd. All rights reserved.</b>
      </div>
      <div>
        <v-row justify="center">
          <social-site></social-site>
        </v-row>
      </div>
      </div>
    </v-footer>
  </v-app>
  </div>
</template>

<script>
import NavDrawer from "./components/NavDrawer";
import SocialSite from './components/SocialSite.vue';

export default {
  name: "App",
  data: () => ({
    drawer: false,
    themeColor: "#ff5722"
  }),
  components: {
    NavDrawer,
    SocialSite
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
    logowidth(){
      return this.mobile ? '12rem' : '14rem'      
    }
  },
  watch: {
    mobile(newValue) {
      if (newValue) {
        this.drawer = false
      }
    }
  },
  methods: {
    hideDrawer(){
      if(this.mobile){
        this.drawer = false;
      }
    }
  }
}
</script>

<style>
  .theme-text {
    color: #ff5722;
  }
</style>