<template>
  <div>  
    <div class="text-center py-2">
      <v-img contain :src="imgURL"></v-img>
      <v-file-input
        chips
        show-size
        :rules="rules.img"
        accept="image/jpeg"
        :placeholder="placeholder"
        :label="label"
        v-model="imgFile"
        @change="processFile"
        :loading="loading"
      ></v-file-input>

      <v-btn v-if="imgURL" text elevation="3" @click="saveFile" :disabled="loading">
        <v-icon>mdi-save</v-icon> {{ loading ? 'Saving image...' : 'Save Image' }} 
      </v-btn>
    </div>
    <v-snackbar v-model="snackbar" top :color="snackType">
      <p>{{ snackMsg }}</p>
    </v-snackbar>
  </div>
</template>

<script>
import { uuid, apiClient } from "@/services";
export default {
  name: "ProcessFile",
  data() {
    return {
      imgURL: null,
      imgFile: null,
      loading: false,
      snackbar: false,
      snackType: '',
      snackMsg: '',
      rules: {
        img: [
          v => !v || v.type.includes('image/jpeg') || 'Upload a .jpg image',
          v => !v || v.size < 150000 || 'Image size should be less than 150KB',
        ],
      }
    }
  },
  props: {
    imagename: {
      type: String,
      default: ''
    },
    filename: {
      type: String,
      default: '',
    },
    filetype: {
      type: String,
      default: ''
    },
    alert: Function,
    label: {
      type: String,
      default: 'New Image (less than 150KB)'
    },
    placeholder: {
      type: String,
      default: 'select a .jpg image'
    }
  },
  computed: {
    fname(){
      return this.filename || `${uuid()}.jpg`;
    },
    hasParent(){
      return this.imagename || this.filename
    },
  },
  methods: {
    processFile(file) {
      if(!file){
        return;
      }
      const reader = new FileReader();
      reader.addEventListener('load', () => (this.imgURL = reader.result), false);
      reader.readAsDataURL(file)
    },
    saveFile() {
      this.loading = true;
      // console.log(this.imgFile)
      let data = { imagename: this.imagename, filename: this.fname, filetype: this.filetype };
      let fd = new FormData();
      fd.append('data', JSON.stringify(data));
      fd.append('file', this.imgFile);
      let config = { header: { 'Content-Type': 'multipart/form-data' } };
      apiClient
        .post('/process_file.php', fd, config)
        .then(res => {
          // console.log(res.data);
          this.loading= false;
          if (res.data.imagename) {
            this.$emit('image-saved');
            this.hasParent && this.alert ? this.alert('success', 'Image saved successfully.') : this.setAlert('success', 'Image saved successfully.');
          } else {
            this.hasParent && this.alert ? this.alert('error', res.data) : this.setAlert('error', res.data);
          }
        })
        .catch(err => {
          this.loading = false;
          this.hasParent ? this.alert('error', err.message) : this.setAlert('error', err.message)
        });
    },
    setAlert(type, msg){
      this.snackbar = true;
      this.snackType = type;
      this.snackMsg = msg;
    },
  }
};
</script>
