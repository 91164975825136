<template>
  <div>
    <carousel-slide></carousel-slide>
    <br>
    <charcoal-products></charcoal-products>
    <team-carousel></team-carousel>
  </div>
</template>

<script>
import CarouselSlide from '@/components/CarouselSlide.vue';
import CharcoalProducts from '@/components/CharcoalProducts.vue';
import TeamCarousel from '@/components/TeamCarousel.vue';

export default {
  name: "Home",
  data() {
    return {
      //
    };
  },
  components: {CarouselSlide, CharcoalProducts, TeamCarousel},
};
</script>
