<template>
  <div title="Best of Nigeria Charcoal Hardwood, online and social media presence and contacts">
    <v-btn icon value="facebook" href="https://wa.me/+2348077678023" target="_blank">
          <!-- <span>Whatsapp</span> -->
          <v-icon>mdi-whatsapp</v-icon>
        </v-btn>

    <v-btn icon value="facebook" href="https://www.facebook.com/U-O-Charcoal-105674508091391/" target="_blank">
          <!-- <span>Facebook</span> -->
          <v-icon>mdi-facebook</v-icon>
        </v-btn>

        <v-btn icon value="twitter" href="https://twitter.com/uocharcoal/" target="_blank">
          <!-- <span>Twitter</span> -->
          <v-icon>mdi-twitter</v-icon>
        </v-btn>
  </div>
</template>

<script>
export default {
  name: "SocialSite"
}
</script>

<style>

</style>