<template>
  <div class="d-block">
    <v-list>
      <v-list-item title="Uocharcoal, best charcoal in nigeria"><v-btn @click="hideDrawer" :to="{name: 'home'}" :color="themeColor" text><v-icon>mdi-home</v-icon> Home</v-btn></v-list-item>
      <v-list-item title="Uocharcoal, best charcoal in nigeria, request quotation"><v-btn @click="hideDrawer" :to="{name: 'quote'}" :color="themeColor" text><v-icon>mdi-clipboard-text</v-icon> Request Quote</v-btn></v-list-item>
      <v-list-item title="Uocharcoal, best charcoal in nigeria, FAQ, frequently ask questions"><v-btn @click="hideDrawer" :to="{name: 'questions'}" :color="themeColor" text><v-icon>mdi-help-circle</v-icon> FAQ</v-btn></v-list-item>
      <v-list-item title="Uocharcoal, best charcoal in nigeria, best of charcoal products in nigeria"><v-btn @click="hideDrawer" :to="{name: 'products'}" :color="themeColor" text><v-icon>mdi-view-list</v-icon> Products</v-btn></v-list-item>
      <v-list-item title="Uocharcoal, the team behind best charcoal in nigeria"><v-btn @click="hideDrawer" :to="{name: 'team'}" :color="themeColor" text><v-icon>mdi-account-multiple</v-icon> Team</v-btn></v-list-item>
      <v-list-item title="Uocharcoal, best charcoal in nigeria, contact us "><v-btn @click="hideDrawer" :to="{name: 'contact'}" :color="themeColor" text><v-icon>mdi-map-marker-radius</v-icon> Contact</v-btn></v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  drawer: false,
  name: 'NavDrawer',
  data: () => ({
    themeColor: "#ff5722"
  }),
  props: {
    hideDrawer: Function,
  }
}
</script>
